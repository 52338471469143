import React from "react";
import Hero from "../components/Hero";
import Benefits from "../components/Benefits";
import Collaboration from "../components/Collaboration";
import Service from "../components/Service";
import Pricing from "../components/Pricing";

function HomePage() {
  return (
    <>
      <Hero />
      <Benefits />
      <Collaboration />
      <Service />
      <Pricing />
    </>
  );
}

export default HomePage;
