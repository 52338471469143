import { useTranslation } from "react-i18next";
import { companyLogos } from "../constants";
import ImageSlider from "./ImageSlider";

const CompanyLogos = ({ className }) => {
  const { t } = useTranslation();
  return (
    <div className={className}>
      <h5 className="body-2 mb-3 lg:mb-6 text-center text-n-1/50">
        {t("We make you source of inspiration for your clints")}
      </h5>
      <ImageSlider />
    </div>
  );
};

export default CompanyLogos;
