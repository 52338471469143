import { curve1, curve2 } from "../../assets";

export const RightCurve = () => {
  return (
    <div className="hidden absolute top-1/2 ltr:left-full rtl:right-full w-[10.125rem] -mt-1 ltr:ml-10 rtl:mr10 pointer-events-none xl:block">
      <img src={curve2} width={162} height={76} alt="Curve 2" />
    </div>
  );
};

export const LeftCurve = () => {
  return (
    <div className="hidden absolute top-1/2 ltr:right-full rtl:left-full w-[32.625rem] -mt-1 ltr:mr-10 rtl:ml-10 pointer-events-none xl:block">
      <img src={curve1} width={522} height={182} alt="Curve 1" />
    </div>
  );
};
