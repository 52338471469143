import { Suspense, useEffect } from "react";
import ButtonGradient from "./assets/svg/ButtonGradient";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";

import HomePage from "./pages/HomePage.jsx";
import { useStateContext } from "./context/ContextProvider";
import { useTranslation } from "react-i18next";
import WaittingPage from "./pages/WaittingPage.jsx";
const App = () => {
  const { setCurrentLang, currentLang, setCurrentDir, currentDir, setDir } =
    useStateContext();
  const { i18n } = useTranslation();
  useEffect(() => {
    const currentThemedir = localStorage.getItem("themeDir");
    const currentThemelang = localStorage.getItem("themeLang");
    if (currentThemedir && currentThemelang) {
      setCurrentDir(currentThemedir);
      setCurrentLang(currentThemelang);
    }
    i18n.changeLanguage(currentLang);
  }, [currentLang]);

  return (
    <frameElement dir={currentDir === "rtl" ? "rtl" : "ltr"}>
      <Suspense fullback={null}>
        <BrowserRouter>
          <div className="pt-[4.75rem] lg:pt-[5.25rem] overflow-hidden">
            <Header />

            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/waitting" element={<WaittingPage />} />
            </Routes>
            <Footer />
          </div>
          <ButtonGradient />
        </BrowserRouter>
      </Suspense>
    </frameElement>
  );
};

export default App;
