import {
  benefitCard1,
  benefitCard2,
  benefitCard3,
  benefitCard4,
  benefitCard5,
  benefitCard6,
  benefitIcon1,
  benefitIcon2,
  benefitIcon3,
  benefitIcon4,
  benefitIcon5,
  benefitIcon6,
  benefitIcon7,
  benefitIcon8,
  benefitIcon9,
  benefitImage2,
  chromecast,
  disc02,
  discord,
  discordBlack,
  facebook,
  figma,
  file02,
  framer,
  homeSmile,
  instagram,
  notification2,
  notification3,
  notification4,
  notion,
  photoshop,
  plusSquare,
  protopie,
  raindrop,
  recording01,
  recording03,
  roadmap1,
  roadmap2,
  roadmap3,
  roadmap4,
  searchMd,
  slack,
  sliders04,
  telegram,
  twitter,
  yourlogo,
  logo1,
  logo2,
  logo3,
  logo4,
  logo5,
  logo6,
  logo7,
  logo8,
  logo9,
  logo10,
  logo11,
  logo12,
  logo13,
  logo14,
  logo15,
  logo16,
  logo17,
  uiux1,
  uiux2,
  uiux3,
  WhatsApp,
} from "../assets";
export const UiUx = [
  {
    id: 1,
    uiuxImage: uiux1,
  },
  {
    id: 2,
    uiuxImage: uiux2,
  },
  {
    id: 3,
    uiuxImage: uiux3,
  },
];
export const LogosCards = [
  {
    id: 1,
    logoImage: logo1,
    backgroundUrl: benefitCard5,
  },
  {
    id: 2,
    logoImage: logo2,
    backgroundUrl: benefitCard3,
  },
  {
    id: 3,
    logoImage: logo3,
    backgroundUrl: benefitCard4,
  },
  {
    id: 4,
    logoImage: logo4,
    backgroundUrl: benefitCard2,
  },
  {
    id: 5,
    logoImage: logo5,
    backgroundUrl: benefitCard1,
  },
  {
    id: 6,
    logoImage: logo6,
    backgroundUrl: benefitCard5,
  },
  {
    id: 7,
    logoImage: logo7,
    backgroundUrl: benefitCard1,
  },
  { id: 8, logoImage: logo8 },
  { id: 9, logoImage: logo9 },
  { id: 10, logoImage: logo10 },
  { id: 11, logoImage: logo11 },
  { id: 12, logoImage: logo12 },
  { id: 13, logoImage: logo13 },
  { id: 14, logoImage: logo14 },
  { id: 15, logoImage: logo15 },
  { id: 16, logoImage: logo16 },
  { id: 17, logoImage: logo17 },
];
export const navigation = [
  {
    id: "0",
    title: "services",
    url: "/waitting",
  },
  {
    id: "1",
    title: "gallary",
    url: "/waitting",
  },
  {
    id: "2",
    title: "apps",
    url: "/waittings",
  },
  {
    id: "3",
    title: "website",
    url: "/waittinge",
  },
  {
    id: "4",
    title: "New account",
    url: "#/waitting",
    onlyMobile: true,
  },
  {
    id: "5",
    title: "Sign in",
    url: "/waitting",
    onlyMobile: true,
  },
];

export const heroIcons = [homeSmile, file02, searchMd, plusSquare];

export const notificationImages = [notification4, notification3, notification2];

export const companyLogos = [yourlogo, yourlogo, yourlogo, yourlogo, yourlogo];

export const brainwaveServices = [
  "Photo generating",
  "Photo enhance",
  "Seamless Integration",
];

export const brainwaveServicesIcons = [
  { id: 1, name: "Logos", icon: benefitIcon1 },
  { id: 2, name: "UI/UX Design", icon: benefitIcon2 },

  { id: 3, name: "3D Product", icon: benefitIcon4 },
  {
    id: 4,
    name: "Product Packaging",
    icon: benefitIcon7,
  },
  { id: 5, name: "Social Media", icon: benefitIcon8 },
];

export const roadmap = [
  {
    id: "0",
    title: "Voice recognition",
    text: "Enable the chatbot to understand and respond to voice commands, making it easier for users to interact with the app hands-free.",
    date: "May 2023",
    status: "done",
    imageUrl: roadmap1,
    colorful: true,
  },
  {
    id: "1",
    title: "Gamification",
    text: "Add game-like elements, such as badges or leaderboards, to incentivize users to engage with the chatbot more frequently.",
    date: "May 2023",
    status: "progress",
    imageUrl: roadmap2,
  },
  {
    id: "2",
    title: "Chatbot customization",
    text: "Allow users to customize the chatbot's appearance and behavior, making it more engaging and fun to interact with.",
    date: "May 2023",
    status: "done",
    imageUrl: roadmap3,
  },
  {
    id: "3",
    title: "Integration with APIs",
    text: "Allow the chatbot to access external data sources, such as weather APIs or news APIs, to provide more relevant recommendations.",
    date: "May 2023",
    status: "progress",
    imageUrl: roadmap4,
  },
];

export const collabText =
  "Choose our company to be part of a unique creative journey, where we will work together to achieve your goals and exceed your expectations. Discover what we can offer you and embark on a digital future with confidence and creativity.";

export const collabContent = [
  {
    id: "1",
    title: "Transforming Customer Vision into Amazing Reality",
    text: "What sets us apart is our deep understanding of our clients' needs and our ability to turn their vision into tangible reality. We combine strong expertise with a comprehensive knowledge of the latest technologies to deliver stunning applications and polished websites that perfectly reflect your brand identity and interact seamlessly with your target audience.",
  },
  {
    id: "2",
    title: "Excellence and Creativity in Development and Design",
    text: "Our talented and specialized team consists of professional developers and creative designers who utilize the latest tools and technologies to achieve the highest levels of performance and aesthetics in every project we undertake. We work hard to ensure that the user experience is easy and enjoyable, and that your business goals are achieved with maximum effectiveness and success.",
  },
  {
    id: "3",
    title: "Innovation and Creativity in Unique and Exceptional Designs",
    text: "We believe in innovation and creativity, which is why we also offer unique and exceptional graphic design services. Whether you need a stunning logo that reflects your company's identity or attractive and innovative marketing materials, we are here to transform your ideas into remarkable designs that speak with a powerful voice and leave an unforgettable impression.",
  },
];

export const collabApps = [
  {
    id: "0",
    title: "Figma",
    icon: figma,
    width: 40,
    height: 40,
  },
  {
    id: "1",
    title: "Notion",
    icon: notion,
    width: 40,
    height: 40,
  },
  {
    id: "2",
    title: "Discord",
    icon: discord,
    width: 40,
    height: 40,
  },
  {
    id: "3",
    title: "Slack",
    icon: slack,
    width: 40,
    height: 40,
  },
  {
    id: "4",
    title: "Photoshop",
    icon: photoshop,
    width: 40,
    height: 40,
  },
  {
    id: "5",
    title: "Protopie",
    icon: protopie,
    width: 40,
    height: 40,
  },
  {
    id: "6",
    title: "Framer",
    icon: framer,
    width: 40,
    height: 40,
  },
  {
    id: "7",
    title: "Raindrop",
    icon: raindrop,
    width: 40,
    height: 40,
  },
];

export const pricing = [
  {
    id: "0",
    title: "Basic",
    description: "AI chatbot, personalized recommendations",
    price: "0",
    features: [
      "An AI chatbot that can understand your queries",
      "Personalized recommendations based on your preferences",
      "Ability to explore the app and its features without any cost",
    ],
  },
  {
    id: "1",
    title: "Premium",
    description: "Advanced AI chatbot, priority support, analytics dashboard",
    price: "9.99",
    features: [
      "An advanced AI chatbot that can understand complex queries",
      "An analytics dashboard to track your conversations",
      "Priority support to solve issues quickly",
    ],
  },
  {
    id: "2",
    title: "Enterprise",
    description: "Custom AI chatbot, advanced analytics, dedicated account",
    price: null,
    features: [
      "An AI chatbot that can understand your queries",
      "Personalized recommendations based on your preferences",
      "Ability to explore the app and its features without any cost",
    ],
  },
];

export const benefits = [
  {
    id: "0",
    title: "Logo and Visual Identity Design",
    text: "Embark on a distinctive business journey with our innovative service for logo and visual identity design. We will work closely with you to understand your vision and values, and transform them into a unique design that reflects your brand identity.",
    backgroundUrl: benefitCard1,
    iconUrl: benefitIcon1,
    imageUrl: benefitImage2,
  },
  {
    id: "1",
    title: "Social Media Advertising Design",
    text: "Capture the attention of your audience and enhance your social media accounts with stunning social media advertisements. We will design innovative and attractive ads that help you achieve your marketing goals.",
    backgroundUrl: benefitCard2,
    iconUrl: benefitIcon8,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: "2",
    title: "Animation Video Design",
    text: "Unleash your creativity with our fantastic service for animation video design. We will take your idea and transform it into an exciting and captivating story using innovative animation techniques.",
    backgroundUrl: benefitCard4,
    iconUrl: benefitIcon3,
    imageUrl: benefitImage2,
  },
  {
    id: "3",
    title: "3D Product Model Design",
    text: "Draw attention to your products with exquisite 3D product design. We will transform your idea into a realistic and appealing 3D model. With our exceptional services, you will have an exceptional and distinctive experience.",
    backgroundUrl: benefitCard3,
    iconUrl: benefitIcon4,
    imageUrl: benefitImage2,
    light: true,
  },
  {
    id: "4",
    title: "Product Packaging Design",
    text: "Make your product stand out on the shelves with our captivating product packaging design service. We combine creativity and functionality to create packaging that not only catches the eye but also communicates the essence of your brand. Our designs provide an attractive and protective solution for your products.",
    backgroundUrl: benefitCard4,
    iconUrl: benefitIcon7,
    imageUrl: benefitImage2,
  },
  {
    id: "5",
    title: "UI/UX Design",
    text: "Enhance your digital products with our exceptional UI/UX design service. We create intuitive and visually appealing user interfaces that ensure a seamless user experience. Our designs are tailored to meet your specific needs and elevate the appeal of your product.",
    backgroundUrl: benefitCard5,
    iconUrl: benefitIcon2,
    imageUrl: benefitImage2,
  },

  {
    id: "6",
    title: "Mobile Apps Development",
    text: "Let us help you bring your app vision to life on the Android and iOS platforms. We offer custom programming and development services that ensure a powerful and advanced application that meets your unique needs and requirements.",
    backgroundUrl: benefitCard1,
    iconUrl: benefitIcon5,
    imageUrl: benefitImage2,
  },
  {
    id: "7",
    title: "Web & E-commerce Development",
    text: "Get a cutting-edge website or e-commerce store that enhances your online presence and delivers an exceptional user experience. We offer website programming and development services with a focus on attractive design, robust functionality, and outstanding performance.",
    backgroundUrl: benefitCard2,
    iconUrl: benefitIcon6,
    imageUrl: benefitImage2,
  },
  {
    id: "8",
    title: "Print Design",
    text: "Experience captivating print designs that reflect your brand's identity and effectively communicate your message. Our creative print design service delivers visually stunning materials, from brochures to business cards, ensuring a lasting impact on your target audience. Transform your ideas into beautifully crafted printed assets with us.",
    backgroundUrl: benefitCard3,
    iconUrl: benefitIcon9,
    imageUrl: benefitImage2,
  },
];

export const socials = [
  {
    id: "1",
    title: "WhatsApp",
    iconUrl: WhatsApp,
    url: "https://wa.me/967773455454",
  },
  {
    id: "2",
    title: "Instagram",
    iconUrl: instagram,
    url: "https://www.instagram.com/art.codey?igsh=eW0xcndoOW90aDZp",
  },

  {
    id: "4",
    title: "Facebook",
    iconUrl: facebook,
    url: "https://www.facebook.com/almaystro1010?mibextid=ZbWKwL",
  },
];
