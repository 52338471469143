import React, { createContext, useContext, useState } from "react";

const StateContext = createContext();

export const ContextProvider = ({ children }) => {
  const [currentDir, setCurrentDir] = useState("rtl");
  const [currentLang, setCurrentLang] = useState("ar");

  const setDir = (lang) => {
    setCurrentDir(lang);
    console.log("lang", lang);
    localStorage.setItem("themeDir", lang);

    const newlang = lang === "ltr" ? "en" : "ar";

    setCurrentLang(newlang);
    localStorage.setItem("themeLang", newlang);
  };

  return (
    <StateContext.Provider
      value={{ setCurrentLang, currentLang, setCurrentDir, currentDir, setDir }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateContext = () => useContext(StateContext);
