import { useState } from "react";
import Section from "./Section";
import Heading from "./Heading";

import { brainwaveServicesIcons, LogosCards, UiUx } from "../constants";

import ImageSlider from "./ImageSlider";
import { useTranslation } from "react-i18next";
import Waitting from "./Waitting";

const Service = () => {
  
  const [selected, setSelected] = useState(1);
  const { t } = useTranslation();
  return (
    <Section id="how-to-use">
      <div className="container ">
        <Heading
          title={t("Portfolio Showcase")}
          text={t(
            "Innovative Designs and Creative Solutions to Meet Your Needs"
          )}
        />
        <div className="mb-5 p-3 grid grid-cols-3 lg:grid-cols-5 flex-row items-center  justify-center">
          {brainwaveServicesIcons.map((item) => (
            <div
              className="items-center flex-col 
                    justify-center 
                    text-center"
              key={item.id}
              onClick={() => setSelected(item.id)}
            >
              <div
                className={`rounded-2xl align-center mx-auto flex items-center justify-center ${
                  item.id === selected
                    ? "w-[3rem] h-[3rem] p-0.25 bg-conic-gradient mb-4 md:w-[4.5rem] md:h-[4.5rem]"
                    : "flex w-10 h-10 bg-n-6 md:w-15 md:h-15"
                }`}
              >
                <div
                  className={
                    item.id === selected
                      ? "flex items-center justify-center w-full h-full  bg-n-7 rounded-2xl"
                      : ""
                  }
                >
                  <img
                    src={item.icon}
                    className="w-[80%] h-[80%] mx-auto"
                    alt={item}
                  />
                </div>
              </div>
              <span
                className={` text-center  mx-auto mt-4  ${
                  item.id == selected ? "h7 text-white" : "body-3 text-n-4"
                }`}
              >
                {t(item.name)}
              </span>
            </div>
          ))}
        </div>
        <div className=""></div>
        <div className="flex-1 my-10 lg:mb-40"> </div>
        <div className="relative lg:mt-10 md:mt-10 backdrop-blur-md rounded-2xl p-5 align-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 border border-n-1/10">
          {selected === 1 ? (
            LogosCards.map((item) => (
              <div
                className="block  relative p-0.5 backdrop-blur-md bg-[#ffffff22] border border-n-1/10 rounded-2xl bg-[length:100%_100%] md:max-w-[24rem] overflow-hidden mb-4"
                key={item.id}
              >
                <img
                  src={item.logoImage}
                  width={300}
                  alt={item.title}
                  className="w-full h-auto object-cover rounded-2xl"
                />
              </div>
            ))
          ) : selected === 2 ? (
            // إضافة الكود الذي يجب تنفيذه عند اختيار selected == 2
            UiUx.map((item) => (
              <div
                className="block  relative p-0.5 backdrop-blur-md bg-[#ffffff22] border border-n-1/10 rounded-2xl bg-[length:100%_100%] md:max-w-[24rem] overflow-hidden mb-4"
                key={item.id}
              >
                <img
                  src={item.uiuxImage}
                  width={300}
                  alt={item.title}
                  className="h-full w-auto object-cover rounded-2xl"
                />
              </div>
            ))
          ) : (
            <Waitting />
          )}
        </div>
      </div>
    </Section>
  );
};

export default Service;
