import React from "react";
import { useTranslation } from "react-i18next";

function Waitting() {
  const { t } = useTranslation();
  return (
    <div className=" p-5 bg-n-8/10 border border-color-3/10 backdrop-blur-md rounded-2xl items-center justify-center text-center ">
      <div className="   ">
        <h1 className="h2 text-color-3 text-center mb-8">
          {t(
            "The website is currently under development, and some sections are not yet ready."
          )}
        </h1>
        <p className=" body-1 text-n-3  text-wrap">
          {t(
            "We apologize for any inconvenience caused. We are pleased to inform you that our website is undergoing development to improve your experience. We are focusing our efforts on developing new sections that will provide you with more content and useful services. We thank you for your patience and understanding during this period. We are working diligently to complete the development and provide you with a wonderful experience. Thank you for your trust in us."
          )}
        </p>
      </div>
    </div>
  );
}

export default Waitting;
