import React from "react";
import Waitting from "../components/Waitting";
import Button from "../components/Button";
import { useTranslation } from "react-i18next";
import Section from "../components/Section";

function WaittingPage() {
  const { t } = useTranslation();
  return (
    <Section>
      <div className="items-center justify-center mx-auto  px-10">
        <Waitting />
        <div className="my-10 mx-auto">
          <Button href="/" white>
            {t("Back Home page")}
          </Button>
        </div>
      </div>
    </Section>
  );
}

export default WaittingPage;
